/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/style.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css";
@import "~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css";
html {
  height: 100%;
}

body {
  background-color: #ddd5cb;
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  padding-top: 132px;
  height: 100%;
  min-height: 100%;

  // @media(max-width: 768px) {
  //     padding-top: 70px;
  // }
  &.remove-padding {
    padding-top: 0;
  }
}

ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  text-rendering: auto;
}

li {
  list-style: none;
}

p {
  @include font-regular(18px, 30px);
}

h1 {
  @include font-bold(36px, normal);
}

h2 {
  @include font-bold(32px, normal);
}

h3 {
  @include font-bold(32px, normal);
}

h4 {
  @include font-bold(18px, normal);
}

h5 {
  @include font-regular(16px, normal);
}

.full-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// sticky navbar
// buttons
.btn-primary {
  background-color: $primary-button-background;
  border-color: $primary-border-background;
  color: #fff;
  @include font-medium(16px, normal);
  border-radius: unset;
  padding: 14px 16px;
  margin-left: 20px;
}
.btn-primary-btn {
  background-color: $primary-button-background;
  border-color: $primary-border-background;
  color: #fff;
  @include font-medium(16px, normal);
  border-radius: unset;
  padding: 14px 16px;
  &:hover {
    color: #fff;
    background-color: $primary-button-background;
    border-color: $primary-border-background;
  }
  &.active,
  &:focus {
    background-color: $primary-button-background;
    border-color: $primary-border-background;
  }
}

.btn-outline-primary {
  border-color: $primary-border-background;
  color: $theme-color;
  @include font-medium(16px, normal);
  padding: 14px 107px;
  border-radius: unset;
  &:hover {
    color: #fff;
    background-color: $primary-button-background;
    border-color: $primary-border-background;
  }
}

.btn-no-border {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.btn-primary:hover {
  color: #fff;
  background-color: $primary-button-background;
  border-color: $primary-border-background;
}

.btn-primary.active,
.btn-primary:focus {
  background-color: $primary-button-background;
  border-color: $primary-border-background;
}

.btn-close:focus,
.btn:focus {
  box-shadow: unset;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn,
.btn-primary,
.btn-outline-primary:active:focus {
  box-shadow: unset;
}

// carousel
.carousel-item {
  opacity: 1;
  cursor: pointer;
  &.active {
    opacity: 1;
    transition: transform 0.35s ease-in-out !important;
  }
}

.signin-modal {
  max-width: 605px;
  // height: 100%;
  align-items: center;
  display: flex;
  // margin: 0 auto!important;
  min-height: calc(100% - 3.5rem);

  @media (max-width: 768px) {
    max-width: 100%;
    // display: unset;
    // height: auto;
  }
  &.signin-modal-height {
    max-width: 420px !important;
    @media (max-width: 768px) {
      margin: 0 auto;
      min-height: auto;
    }
  }
  &.edit-invite-modal {
    max-width: 670px !important;
    .modal-body {
      padding: 50px 48px 50px;
      .select-modal-sec {
        padding: 0;
      }
    }
  }
  .signin-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &.confirm-itinerary-modal {
    max-width: 488px !important;
    .modal-body {
      p {
        color: #222;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        padding: 0 26px;
      }
    }
  }
}

.modal-header {
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    flex-direction: column;
    position: fixed;
    right: 20px;
    top: 20px;
  }
  img.img-fluid {
    cursor: pointer;
  }
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem 0rem -0.5rem 0px;
  position: absolute;
  right: 12px;
  top: 33px;
  z-index: 9;
  @media (max-width: 922px) {
    right: 20px;
  }
  @media (max-width: 768px) {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 2;
    font-size: 18px;
  }
}

.modal-footer-img {
  width: 100%;
  height: 34px;
  @media (max-width: 767px) {
    width: 80%;
  }
}

.modal-header-img {
  width: 100%;
  height: 32px;
  @media (max-width: 767px) {
    width: 80%;
  }
}

// checkbox
.form-check-input:checked[type="radio"] {
  background-image: url(./assets/images/radiobutton-active.svg) !important;
  border: none !important;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url(./assets/images/checkbox-tick.svg) !important;
  background-size: auto;
}

.form-check-input:focus {
  box-shadow: unset;
}

.form-check-input[type="checkbox"] {
  border: 1px solid #616161 !important;
  border-radius: unset;
  cursor: pointer;
}

.form-check-input {
  width: 24px;
  height: 24px;
}

.form-check .form-check-input {
  float: left;
  margin: 5px 10px 0 -1.5em;
}

.checkbox-input-member {
  margin-bottom: 18px;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
}

.form-check .form-check-input[type="radio"] {
  float: left;
  margin: 0px 16px 0 -1.5em !important;
  cursor: pointer;
}

// form control
.form-control:focus {
  box-shadow: unset;
  border-color: $input-border-active-color;
}

.form-select:focus {
  border-color: #ced4da;
  box-shadow: unset;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: unset;
}

// dropdown-item
.dropdown-menu {
  min-width: 145px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 8.5px 15px;
  clear: both;
  @include font-regular(13px, 23px);
  color: #000;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item.hover,
.dropdown-item:hover {
  color: $dropdown-item-active-color;
  text-decoration: none;
  background-color: $dropdown-item-focus-background;
}

// calender
.bs-datepicker {
  margin-top: 10px;
  left: 26px;
  box-shadow: unset;
  // @media(max-width: 1200px) {
  //     left: 108px;
  // }
  // @media(max-width: 1024px) {
  //     margin-top: 0px;
  // }
  // @media(max-width: 767px) {
  //     left: 29px;
  // }
  // @media(max-width: 675px) {
  //     left: 0px;
  // }
  // @media(max-width: 606px) {
  //     left: 0px;
  // }
  // @media(max-width: 576px) {
  //     margin-top: 0px;
  // }
}

.birthDate {
  @media (max-width: 1024px) {
    margin-top: 10px;
  }
  @media (max-width: 767px) {
    left: 0px;
  }
  @media (max-width: 576px) {
    left: 20px;
  }
  @media (max-width: 518px) {
    left: 40px;
  }
  @media (max-width: 460px) {
    left: 60px;
  }
}

.deathDate {
  @media (max-width: 1024px) {
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    left: -19px;
  }
  @media (max-width: 576px) {
    left: -20px;
  }
  @media (max-width: 518px) {
    left: -40px;
  }
  @media (max-width: 460px) {
    left: -60px;
  }
}

.expiryDate {
  left: 0;
  top: 0px;
  @media (max-width: 1024px) {
    top: -101px;
  }
  @media (max-height: 990px) {
    top: -101px;
  }
  .bs-datepicker-head {
    min-width: 270px;
    height: 50px;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    text-align: justify;
  }
  .bs-datepicker-body {
    padding: 5px;
    border-radius: 0 0 3px 3px;
    min-height: 172px;
    min-width: 280px;
    border: 1px solid #e9edf0;
    table.months td {
      height: 40px;
    }
  }
  .bs-media-container {
    border: 1px solid #e7dfc3;
    border-radius: 5px;
    padding: 5px;
  }
}

.edit-birthDate {
  @media (max-width: 480px) {
    left: 56px;
  }
  @media (max-width: 390px) {
    left: 76px;
  }
}

.edit-deathDate {
  @media (max-width: 576px) {
    left: 0;
  }
  @media (max-width: 540px) {
    left: -33px;
  }
  @media (max-width: 414px) {
    left: -63px;
  }
}

.theme-green .bs-datepicker-head {
  background-color: #fff;
  text-align: center;
}

.bs-datepicker-head button {
  color: #222222;
}

.bs-datepicker-container {
  padding: 0;
}

.bs-media-container {
  border: 1px solid #e7dfc3;
  border-radius: 5px;
  padding: 15px;
}

.bs-days-calendar-view {
  border: 1px solid red;
}

.bs-datepicker-body {
  border: unset;
}

.bs-datepicker-body table td span {
  color: #222222;
  @include font-regular(14px, 22px);
  letter-spacing: 1.27px;
}

.bs-datepicker-body table th {
  @include font-regular(14px, 22px);
  color: #30353c;
  font-weight: 400;
  text-transform: uppercase;
  padding-right: 16px !important;
  @media (max-width: 768px) {
    font-size: 12px;
    padding-right: 12px !important;
  }
}

.bs-datepicker-body table.days span {
  padding: 0 8px;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #222222;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #885d40 !important;
  color: #fff;
  padding: 0;
}

.signin-modal-content {
  border: unset;
  padding-top: 20px;
  height: 100%;
  position: relative;
  @media (max-width: 768px) {
    min-height: 100%;
    width: 100%;
  }
  @media (max-width: 767px) {
    margin-top: 40px;
  }
  .signin-modal-header {
    border: unset;
    border-radius: unset;
    padding: 0 100px;
    @media (min-width: 768px) {
      padding: 0 16px 0 80px;
    }
  }
}

.modal-content {
  border: unset;
  padding: 0px;
  @media (min-width: 922px) {
    border: 0;
    border-radius: 11px;
    padding: 0px;
  }
}

.modal {
  background-color: unset;
  @media (max-width: 768px) {
    background-color: #fff;
  }
}

.signin-body {
  padding: 0px 80px 20px 78px;
  @media (max-width: 430px) {
    padding: 16px;
  }
  .signin-title {
    @include fontPlayfair-bold(32px, normal);
    color: $signin-title-color;
    margin-bottom: 11px;
    @media (max-width: 540px) {
      @include fontPlayfair-bold(28px, normal);
    }
  }
  .signin-para {
    @include font-regular(14px, 20px);
    color: $signin-para-color;
    margin-bottom: 40px;
  }
  .full-input {
    padding: 19px 16px;
    border: 1px solid #616161;
    margin-bottom: 34px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  input {
    outline: none;
    border: none;
    display: block;
    @include font-regular(16px, normal);
    color: #222222;
  }
  label {
    display: block;
    color: #616161;
    @include font-regular(16px, normal);
  }
  .signin-container {
    .forgot-pw {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
    .form-action {
      width: 100%;
      .btn-primary {
        width: 100%;
        margin: 0 0 22px 0;
      }
    }
    .forgot-link {
      color: $link-text-color;
      @include font-medium(14px, normal);
      margin-bottom: 30px;
    }
    .signup-link {
      color: $link-text-color;
      @include font-medium(14px, normal);
    }
    .signup-para {
      @include font-regular(14px, normal);
      margin-bottom: 36px;
    }
  }
}

// Input field start here
.form-floating {
  border: 1px solid #616161;
  margin-bottom: 34px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.margin-btm-0 {
  margin-bottom: 0 !important;
}

.pw-input {
  margin-bottom: 30px !important;
}
.pw-input-40 {
  margin-bottom: 40px !important;
}

.hide-eye {
  margin-right: 16px;
  cursor: pointer;
}

// invalid input
.invalid-input {
  border: 1px solid red !important;
  margin-bottom: 4px;
}
.invalid-input-text {
  // position: absolute;
  color: red;
  bottom: 12px;
  margin-top: -30px;
  margin-bottom: 30px;
  display: block;
  @include font-regular(12px, normal);
}
.invalid-input-text-20 {
  // position: absolute;
  color: red;
  bottom: 12px;
  margin-top: -20px;
  margin-bottom: 34px;
  display: block;
  @include font-regular(12px, normal);
}
.invalid-input-text-msg {
  position: absolute;
  color: red;
  bottom: 12px;
  // margin-bottom: 34px;
  display: block;
  @include font-regular(12px, normal);
}
.invalid-input-text-neg {
  position: absolute;
  color: red;
  bottom: -16px;
  // margin-bottom: 34px;
  display: block;
  @include font-regular(12px, normal);
}
.invalid-input-text-date {
  position: absolute;
  color: red;
  bottom: -18px;
  left: 0;
  display: block;
  @include font-regular(12px, normal);
}

// no result found
.no-result-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 82px;
  min-height: 250px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    align-items: center;
    margin: 20px 0 70px 0;
  }
  .no-result-img {
    img {
      width: 100%;
      @media (max-width: 768px) {
        // width: 68%;
        scale: 0.7;
      }
    }
  }
  h2 {
    @include font-bold(26px, normal);
    font-size: 26px !important;
    color: rgba(136, 93, 64, 0.5) !important;
    margin-top: 20px !important;
    margin-bottom: 17px;
    opacity: 1;
    @media (max-width: 768px) {
      @include font-bold(26px, normal);
    }
  }
}

// memory code number
.code-number-floating {
  width: 100%;
  max-width: 824px;
  margin-right: 8px;
  @media (max-width: 1200px) {
    max-width: 584px;
  }
  @media (max-width: 991px) {
    min-width: 584px;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 17px !important;
    margin-right: 0px;
  }
  @media (max-width: 767px) {
    min-width: 100%;
  }
  .code-number-input {
    width: 100% !important;
    margin-right: 0 !important;
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
}

// change password
.change-password-content {
  @media (max-width: 767px) {
    margin-top: 40px;
  }
  .change-password-body {
    .change-password-container {
      .invalid-input-text {
        margin-bottom: 30px;
      }
      // .form-floating>label {
      //     padding: 19px 0.75rem;
      // }
      label {
        @include font-regular(16px, normal);
        color: $label-text-color;
      }
      .btn-primary {
        margin-bottom: 36px;
      }
    }
  }
}

// add member start here
.add-member-wrapper {
  .add-member-floating {
    display: inline-flex;
    justify-content: flex-start;
  }
}

button,
a,
select {
  cursor: pointer;
}

#deathDate,
#birthDate {
  background: url(./assets/images/calendar-white-icon.svg) no-repeat 94% center;
  background-color: #fff;
}

.form-floating > .form-select {
  padding: -1px 16px;
  height: calc(3.5rem + 2px);
}

.search-results-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    --bs-gutter-x: 0;
  }
  .form-control {
    width: auto;
    @include font-regular(16px, normal);
    color: #222222;
  }
  .form-floating {
    margin-bottom: 0 !important;
    border: unset;
  }
  .mb-res-15 {
    @media (max-width: 767px) {
      margin-bottom: 15px !important;
    }
  }
  .form-control,
  .form-select {
    border: 1px solid #616161;
    border-radius: unset;
    width: 200px;
    @media (max-width: 1200px) {
      width: 140px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.state-label {
  margin-top: 12px !important;
}

.burial-state-label {
  margin-top: 12px !important;
}

.link-text {
  @include font-semi-bold(14px, normal);
  color: $link-text-color;
  margin-left: 16px;
  display: inline-block;
  @media (max-width: 767px) {
    @include font-semi-bold(12px, normal);
    margin: 0px 0 10px 0;
  }
  &:hover {
    color: $link-text-color;
  }
}

option {
  @include font-medium(14px, normal);
  color: #123167;
}

.form-des-text {
  @include font-regular(12px, 20px);
  color: #222222;
  margin-bottom: 20px;
  span {
    @include font-semi-bold(12px, 20px);
    color: #123167;
  }
}

// upgrade plan
.payment-col {
  border-right: 1px solid #885d40;
  width: 25.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    border-right: unset;
    width: 100%;
  }
}

.payment-text {
  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
  }
  h1 {
    @include font-extra-bold(28px, normal);
    color: #885d40;
    margin-bottom: 5px;
    @media (max-width: 767px) {
      margin-right: 15px;
    }
  }
  &.payment-text-upgrade {
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
}
.upgrade-text {
  padding-left: 10px;
  h2 {
    @include fontPlayfair-bold(26px, normal);
    color: #123167;
    margin-bottom: 3px;
    @media (max-width: 767px) {
      margin-bottom: 8px;
    }
  }
  p {
    @include font-regular(12px, 20px);
    color: #616161;
    margin-bottom: 17px;
    @media (max-width: 767px) {
      @include font-regular(14px, 20px);
      color: #616161;
      margin-bottom: 27px;
    }
  }
  .upgrade-text-para {
    width: 110%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .plan-size {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @media (max-width: 767px) {
      flex-direction: column;
      text-align: left;
    }
    h5 {
      @include font-medium(12px, 20px);
      color: #123167;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0;
      @media (max-width: 767px) {
        @include font-medium(14px, 20px);
        width: 100%;
        &:first-child {
          margin-bottom: 23px;
        }
      }
      img {
        margin-right: 8px;
      }
    }
    &.plan-size-text {
      h5 {
        @media (max-width: 767px) {
          display: flex;
          align-items: center;
          justify-content: center;
          &:first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.upgrade-text-mob {
    @media (max-width: 767px) {
      text-align: center;
    }
  }
}

.one-time-text {
  @include font-semi-bold(18px, normal);
  color: #885d40;
}

.upgrade-btn {
  width: 100%;
  padding: 14px 0;
  margin: 0 0 22px 0;
}

.upgrade-link-btn {
  text-align: center;
  width: 100%;
  @include font-regular(14px, normal);
  color: #222222;
  text-decoration: none;
  padding: 0;
  margin-bottom: 22px;
  &:hover {
    color: #885d40;
  }
}

.footer-heart-img {
  text-align: center;
  width: 100%;
  display: inline-block;
}

.modal-title {
  @include font-bold(32px, 26px);
  text-align: center;
  display: inline-block;
  flex: 1;
  margin-bottom: 36px;
  width: 100%; // @media(min-width: 992px) {
  //     display: inline-block;
  //     flex: 1;
  // }
}

// modal buttons
.edit-btn {
  @include font-semi-bold(16px, normal);
  color: #885d40;
  padding: 0;
  margin-right: 19px;
  &:hover {
    color: #885d40;
  }
}

.modal-update-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-primary-cancel,
.btn-primary-update {
  width: 50%;
  margin-bottom: 36px;
  padding: 14px 0;
  text-transform: capitalize;
}

.verify-btn-des {
  @include font-regular(12px, normal);
  color: #616161;
  margin-bottom: 24px !important;
}

.form-control {
  @include font-regular(16px, normal);
  color: #222222;
}
.has-error {
  border-color: red;
}

// purchase memory code start here
.purchase-memory-logo {
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
}

.purchase-memory-wrapper-left {
  background: url(./../src/assets/images/payment-blue-bg.png) no-repeat center;
  background-size: cover;
  padding: 110px 40px 125px 80px;
  color: #fff;
  height: 100vh;
  min-height: 100%;
  margin-bottom: 20px;
  @media (max-height: 900px) {
    padding: 50px 30px 40px 40px;
  }
  h1 {
    @include fontPlayfair-semi-bold(34px, normal);
    margin-bottom: 14px;
    @media (max-width: 1279px) {
      @include fontPlayfair-semi-bold(30px, normal);
    }
  }
  p {
    @include font-regular(16px, 30px);
    margin-bottom: 14px;
    @media (max-width: 1279px) {
      @include font-regular(14px, 30px);
    }
    @media (max-height: 899px) {
      line-height: 25px;
    }
  }
  .qr-code-para {
    @include fontPlayfair-regular(16px, 30px);
  }
  .last-para-margin {
    margin-bottom: 54px;
  }
  .rip-qr-code-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    @media (max-width: 1279px) {
      width: 100%;
      display: block;
    }
    .purchase-qr-img {
      @media (max-width: 1279px) {
        width: 50%;
      }
    }
    p {
      position: relative;
      bottom: -7px;
      left: 5px;
      @media (max-width: 1279px) {
        @include font-regular(14px, 30px);
        margin-top: 16px;
      }
    }
    .payment-guide-arrow {
      position: absolute;
      top: -74px;
      left: -14px;
      @media (max-width: 1279px) {
        left: 170px;
      }
      @media (max-width: 1200px) {
        left: 150px;
      }
      @media (max-width: 1024px) {
        left: 142px;
      }
    }
  }
}

// right section start here
.navbar-light .navbar-nav .nav-link {
  color: #123167;
  @include font-medium(14px, normal);
  margin-right: 40px;
  &.nav-item-golden {
    color: #885d40;
    margin-right: 20px;
  }
}

.payment-checkbox-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 34px;
  margin-top: -16px;
  .form-check {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &.margin-bottom {
    margin-bottom: 18px;
  }
}

.add-detail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 65px;
  opacity: 0.5;
  cursor: pointer;
  @media (max-width: 595px) {
    margin-right: 40px;
  }
  // @media(max-width: 412px) {
  //     margin-right: 28px;
  // }
  &.active {
    opacity: 1;
  }
  &:last-child {
    margin-right: 0px;
  }
}

.add-detail-number {
  padding: 1px 8px;
  background-color: #885d40;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: #fff;
  @include font-bold(16px, normal);
  margin-right: 10px;
}

.add-detail-text {
  @include font-semi-bold(14px, normal);
  color: #222222;
  // @media(max-width: 412px) {
  //     @include font-semi-bold(12px, normal);
  // }
}

// memory code order
.memory-code-order {
  padding: 26px 30px;
  margin-bottom: 36.5px;
  background-color: rgba(129, 95, 68, 0.1);
  border-radius: 6px;
  position: relative;
  border: 1px solid #eeeeee;
  &.with-price{
    padding-right: 70px;
    .price-text{
      top: 56px;
    }
  }
  .visa-title {
    font-size: 19px;
    font-weight: 600;
    line-height: 25.95px;
    letter-spacing: -0.15667779743671417px;
    text-align: left;
    margin-bottom: 4px;
    color: #000;
    font-family: "Manrope", sans-serif;
  }
  .visa-expire {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    color: #000;
  }
  & > p {
    @include fontPlayfair-semi-bold(12px, 20px);
    color: #885d40;
    margin-bottom: 8px;
  }
  .memory-code-order-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.memory-code-block {
      display: block;
    }
    p {
      @include font-regular(16px, normal);
      color: #222222;
      margin-bottom: 0;
      // @media(max-width: 540px) {
      //     padding-right: 60px;
      // }
    }
    @media (max-width: 414px) {
      justify-content: flex-start;
    }
  }
}

.para-color-text {
  @include font-semi-bold(14px, 20px);
  color: #123167;
  margin-bottom: 4px;
  display: inline-block;
  &.bottom-margin {
    margin-bottom: 33px;
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
  &.upgrade-plan2-heading {
    @include font-bold(20px, normal);
    margin-bottom: 18px;
  }
}

.form-check-input:checked {
  background-color: #fff;
  border-color: #885d40;
}

.payment-order-btn {
  @media (max-width: 1081px) {
    width: 100%;
  }
  .btn-primary {
    width: 100%;
    margin-left: 0;
    @media (max-width: 1081px) {
      width: 100%;
    }
  }
  .btn-outline-primary {
    width: 100%;
    padding: 14px 0;
    @media (max-width: 1081px) {
      width: 100%;
    }
  }
}

// purchase success section
.pur-success-section {
  .purchase-success-form {
    padding: 0 87px 0 88px;
    @media (max-width: 767px) {
      padding: 16px;
    }
    &.payment-success-form {
      padding: 0 40px 0 40px;
      @media (max-width: 414px) {
        padding: 16px;
      }
    }
    .purchase-success-col {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 150px 0 158px 0;
      @media (max-width: 575px) {
        padding: 50px 0 30px 0;
      }
      &.payment-success-col {
        padding: 50px 0 50px 0;
        @media (max-width: 767px) {
          padding: 20px 0 20px 0;
        }
        .payment-des {
          @media (max-width: 767px) {
            margin-bottom: 40px;
          }
        }
      }
      .pur-success-log {
        margin-bottom: 16px;
      }
      .success-title {
        @include fontPlayfair-semi-bold(36px, normal);
        color: #123167;
        margin-bottom: 16px;
        @media (max-width: 575px) {
          @include fontPlayfair-semi-bold(28px, normal);
        }
      }
      p {
        @include font-semi-bold(14px, 20px);
        color: #123167;
        margin-bottom: 45px;
        @media (max-width: 575px) {
          margin-bottom: 86px;
        }
      }
    }
  }
}

.purchase-success-btn {
  .btn-primary {
    padding: 14px 86px 14px 87px;
    margin-left: 0;
    @media (max-width: 375px) {
      padding: 14px 66px 14px 67px;
    }
  }
}

// carousel-file-input
.carousel-file-input {
  width: 164.1px;
  height: 207px;
  object-fit: cover;
  background-color: #fffdf6;
  color: #222222;
  @media (max-width: 1200px) {
    width: 137.1px;
    height: 173px;
  }
  @media (max-width: 1199px) {
    width: 131.1px;
    height: 170px;
  }
  @media (max-width: 991px) {
    width: 77%;
    height: 225px;
  }
  @media (max-width: 768px) {
    width: 77%;
    height: 229px;
  }
  @media (max-width: 767px) {
    width: 74%;
    height: 135px;
  }
  @media (max-width: 575px) {
    width: 75%;
    height: 330px;
  }
  @media (max-width: 540px) {
    width: 72%;
    height: 287px;
  }
  @media (max-width: 414px) {
    width: 74%;
    height: 170px;
  }
  @media (max-width: 390px) {
    width: 70%;
    height: 152px;
  }
  @media (max-width: 375px) {
    width: 70%;
    height: 133px;
  }
  @media (max-width: 360px) {
    width: 70%;
    height: 124px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  input[type="file"] {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 1px;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

// Custom Editor
.tox.tox-tinymce {
  height: 270px !important;
  border: 1px solid #e7dfc3 !important;
}

.tox-toolbar__primary {
  background-color: #ddd5cb !important;
  border: solid 1px #885d40 !important;
  // border-radius: 2px !important;
  z-index: 5;
}

.memory-code-footer {
  display: flex;
  justify-content: center;
}

// qr-code-des
.qr-code-des {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  .qr-code-des-header {
    @include fontPlayfair-bold(18px, normal);
    color: #123167;
    margin-left: 17px;
    display: inline-block;
    p {
      @include font-regular(12px, normal);
      color: #616161;
      margin-top: 7px;
    }
  }
}

.qr-code-btn-mobile {
  width: 100%;
  margin-left: 0;
}

.border-text {
  position: absolute;
  @include font-regular(12px, normal);
  color: #123167;
  top: -17px;
  left: 46%;
  padding: 8px;
  background-color: #ddd5cb;
}

// error alert
.toast-error {
  background-color: #df2d0f;
  background-image: url(./assets/images/erroralert.svg) !important;
}

.toast-success {
  background-color: #0a8e30;
  background-image: url(./assets/images/alert-success.svg) !important;
}

.margin-right-0 {
  margin-right: 0;
}

// add member text field
.main-container {
  margin: 0 auto;
  .add-member-wrapper {
    border: 1px solid $section-border-color;
    border-radius: 5px;
    margin-bottom: 48px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    min-width: 80%;
    padding-top: 10px;

    @media (max-width: 767px) {
      margin-bottom: 0;
      border: unset;
      border-left: unset;
      border-right: unset;
      border-radius: unset;
      padding-bottom: 0px;
      width: 100%;
    }
    &.wrapper-w-custom {
      width: 80%;
      margin: 0 auto 48px auto;
      @media (max-width: 1199px) {
        width: 90%;
      }
      @media (max-width: 991px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }
    .header-img-infinity {
      position: absolute;
      top: -18px;
      left: calc(50% - 17px);
      @media (max-width: 767px) {
        display: none;
      }
    }
    .footer-img-heart {
      position: absolute;
      bottom: -18px;
      left: calc(50% - 17px);
      @media (max-width: 767px) {
        display: none;
      }
    }
    .add-member-header {
      padding: 0px 32px 0 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 767px) {
        align-items: center;
        padding: 0px 15px;
      }
      &.add-pic-video-header {
        display: block;
        @media (max-width: 991px) {
          padding-top: 30px;
        }
        @media (max-width: 767px) {
          padding-top: 15px;
        }
      }
      h1 {
        @include fontPlayfair-semi-bold(38px, normal);
        color: $h1-color;
        margin-bottom: 8px;
        margin-top: 15px;
        @media (max-width: 991px) {
          @include fontPlayfair-semi-bold(32px, normal);
        }
        @media (max-width: 767px) {
          @include fontPlayfair-semi-bold(26px, normal);
        }
        @media (max-width: 540px) {
          padding-right: 30px;
        }
      }
      p {
        @include font-regular(16px, normal);
        color: #885d40;
        margin-bottom: 35px;
        @media (max-width: 767px) {
          @include font-regular(12px, normal);
          margin-bottom: 24px;
        }
      }
      p.add-pic-text {
        @media (max-width: 767px) {
          @include font-regular(16px, normal);
          margin-bottom: 19px;
        }
      }
      h2 {
        @include font-extra-bold(94px, normal);
        color: #e7dfc3;
        opacity: 0.3;
        @media (max-width: 991px) {
          @include font-extra-bold(84px, normal);
        }
        @media (max-width: 767px) {
          @include font-extra-bold(50px, normal);
        }
      }
      &.itinerary-header {
        padding: 0 40px;
        @media (max-width: 767px) {
          padding: 0 16px;
        }
        h1 {
          margin-top: 40px;
          margin-bottom: 30px;
        }
      }
    }
  }
}

// about memorial section
.about-memorial-section {
  width: 100%;
  padding: 0 32px 32px 32px;
  @media (max-width: 991px) {
    padding: 0 31px 32px 31px;
  }
  @media (max-width: 767px) {
    padding: 0 16px 32px 16px;
  }
  .sub-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      @include font-regular(12px, normal);
      color: #885d40;
    }
  }
  h5 {
    @include font-semi-bold(16px, normal);
    color: #123167;
  }
  .paragraph {
    max-width: 788px;
    width: 100%;
    height: 404px;
    background-color: #fff;
    border: solid 1px #e7dfc3;
  }
}

.member-buttons {
  width: 100%;
  @media (max-width: 767px) {
    position: relative;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .extra-padding {
    padding: 14px 32px !important;
    width: 250px;
    @media (max-width: 991px) {
      width: 200px;
    }
    @media (max-width: 768px) {
      width: 200px;
    }
    @media (max-width: 767px) {
      padding: 14px 20px !important;
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }
    @media (max-width: 380px) {
      width: 100%;
    }
    @media (max-width: 375px) {
      padding: 14px 20px !important;
    }
  }
  .save-next-btn {
    margin-left: 20px;
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
}

.image-preview-wrapper {
  position: relative;
  .image-preview {
    width: 100%;
    object-fit: contain;
    border-radius: 6px;
    @media (max-height: 600px) {
      height: 480px;
    }
  }
}

.image-preview-close-btn {
  position: absolute;
  padding: 0;
  top: 24px;
  right: 24px;
  @media (max-width: 921px) {
    right: 24px;
  }
  @media (max-height: 600px) {
    right: 114px;
  }
}

// owl-carousel
.owl-carousel .owl-item img {
  display: block;
  max-width: 100%;
  max-height: 295px;
  object-fit: cover;
}

// image preview modal
.image-preview-modal-dialog {
  max-width: 605px;
  width: 100%;
  max-height: 761px;
  @media (max-width: 575px) {
    margin: 0;
  }
}

.image-preview-modal {
  background-color: #616161 !important;
  // @media(max-width: 768px) {
  // background-color: #616161 !important;
  // }
}

.image-preview-modal-content {
  padding: 0;
  background-color: unset !important;
}

// about us section
.about-us-section {
  padding: 40px 0 0 0;
  @media (max-width: 768px) {
    padding-bottom: 140px;
  }
  &.about-us-wrap {
    .about-us-sec {
      display: flex;
      justify-content: center;
      .about-text-para {
        border: 1px solid #885d40;
        width: 500px;
        text-align: center;
        padding: 20px;
        box-shadow: 3px 3px 2px 2px #885d40;
        @media (max-width: 480px) {
          width: auto;
        }
        h2 {
          @include font-bold(35px, 30px);
          text-decoration: underline;
          margin-top: 30px;
          margin-bottom: 30px;
          color: #885d40;
        }
        p {
          @include font-regular(18px, 30px);
          margin-bottom: 20px;
          color: #885d40;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
  &.padding-bottom {
    padding-bottom: 60px;
    @media (max-width: 767px) {
      padding-bottom: 112px;
    }
  }
  @media (max-width: 767px) {
    padding: 26px 16px 112px 16px;
  }
  @media (max-width: 576px) {
    padding: 26px 0px 130px 0px;
  }
  .section-title {
    @include fontPlayfair-semi-bold(38px, normal);
    color: #885d40;
    text-align: center;
    margin-bottom: 20px;
    &.text-left {
      text-align: left;
    }
  }
  .about-text-para {
    @include font-regular(18px, 30px);
    color: #222222;
    margin-bottom: 20px;
    li {
      list-style-type: disc;
    }
    @media (max-width: 767px) {
      @include font-regular(16px, 28px);
    }
    p {
      @include font-regular(18px, 30px);
      color: #222222;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        @include font-regular(16px, 28px);
      }
      span {
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
  .image-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .row {
      width: 100%;
      .about-img-col {
        .image-wrapper {
          border: solid 0.8px rgba(137, 113, 27, 0.42);
          padding: 6px;
          border-radius: 18.5px;
          display: inline-block;
          width: 100%;
          height: 266px;
          object-fit: cover;
          @media (max-width: 1399px) {
            height: 236px;
          }
          @media (max-width: 1199px) {
            height: 196px;
          }
          @media (max-width: 991px) {
            height: 136px;
          }
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          @media (max-width: 414px) {
            height: 96px;
            border-radius: 12px;
          }
          img {
            border-radius: 12px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

// label-floating-card
.label-floating-card {
  opacity: 1 !important;
  transform: scale(1) translateY(0.1rem) translateX(0.15rem) !important;
}

.form-control:disabled + label,
.form-control[readonly] + label {
  color: #616161;
}

.form-select:disabled + label {
  color: #616161;
}

// note
.note {
  @include font-regular(12px, normal);
  color: $note-color;
  width: 100%;
}

.search-modal-dialog {
  margin: 0px !important;
}

.form-check-label {
  @include font-regular(16px, normal);
}

.search-clear-all {
  @include font-medium(12px, normal);
  color: #885d40;
  margin-bottom: 10px;
  &:hover {
    color: #885d40;
  }
}

// Overlay
.menu-overlay {
  @media (max-width: 767px) {
    position: fixed;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    z-index: 9;
    top: 0;
    bottom: 0;
  }
}

// std code
.std-code {
  position: absolute;
  top: 26px;
  left: 14px;
  @include font-regular(16px, normal);
  color: #222222;
}

.contact-num-input {
  padding-left: 35px !important;
}

.img-video-item {
  border-radius: 6px;
  margin-bottom: 24px !important;
  position: relative;
  @media (max-width: 480px) {
    width: 50% !important;
  }
  .view-all-memory-video,
  .view-all-memory-img {
    @media (max-width: 1199px) {
      width: 100%;
      height: 126px;
      object-fit: cover;
      max-width: 100%;
    }
    @media (max-width: 991px) {
      height: 86px;
    }
    @media (max-width: 767px) {
      height: 116px;
    }
    @media (max-width: 414px) {
      height: 96px;
    }
  }
}

.video-icon {
  z-index: 6;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  .video-icon-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.video-icon-sm {
  z-index: 6;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  .video-icon-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 800px) {
  body.modal-open nav.navbar.fixed-top,
  body.modal-open .guide-arrow {
    display: none;
  }
}

// .scroll-remove {
//     @media(max-width: 767px) {
//         overflow: hidden;
//     }
// }

.header-img-mobile {
  display: none;
  @media (max-width: 991px) {
    display: none;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 767px) {
    display: flex;
  }
  img {
    width: 61%;
  }
}

.footer-img-mobile {
  display: none;
  @media (max-width: 991px) {
    position: absolute;
    bottom: -123px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media (max-width: 768px) {
    bottom: -115px;
  }
  @media (max-width: 767px) {
    display: flex;
  }
  @media (max-width: 414px) {
    bottom: -105px;
  }
  &.more-bottom {
    @media (max-width: 414px) {
      bottom: -120px !important;
    }
  }
  img {
    width: 68%;
  }
}

// qr code text
.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 210px;
  h4 {
    @include font-bold(28px, normal);
    color: #d6c788;
  }
}

// Contact us
.contact-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .contact-us-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px 40px;
    border: 1px solid #885d40;
    box-shadow: 3px 3px rgba(156, 122, 30, 0.5);
    @media (max-width: 768px) {
      margin-bottom: 100px;
    }
    @media (max-width: 476px) {
      padding: 16px;
    }
    h2 {
      @include font-bold(38px, normal);
      color: #885d40;
      margin-bottom: 40px;
      text-decoration: underline;
      @media (max-width: 476px) {
        @include font-bold(32px, normal);
      }
    }
    p {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      color: #885d40;
      @include font-medium(16px, 32px);
      @media (max-width: 413px) {
        @include font-medium(14px, normal);
      }
      img {
        margin-right: 2px;
      }
      &.email-text {
        @media (max-width: 367px) {
          @include font-medium(12px, normal);
        }
      }
    }
  }
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: #616161;
}
.form-floating > label {
  top: 2px !important;
  color: #616161;
}
.normal-para {
  p {
    word-break: break-word;
    @include font-regular(22px, 40px);
    @media (max-width: 768px) {
      @include font-regular(14px !important, 1.83);
    }
    @media (max-width: 767px) {
      @include font-regular(12px !important, 1.83);
    }
  }
  ul {
    li {
      @include font-regular(22px, 40px);
      @media (max-width: 768px) {
        @include font-regular(14px !important, 1.83);
      }
      @media (max-width: 767px) {
        @include font-regular(12px !important, 1.83);
      }
    }
  }
}
.border-solid {
  border: solid 1px #e7dfc3 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-res-10 {
  @media (max-width: 767px) {
    margin-bottom: 10px !important;
  }
}
.having-issues-body {
  padding: 0px 60px 20px 60px;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
}
.having-issues-title {
  color: #123167;
  @include font-bold(20px, normal);
  margin-bottom: 8px;
}
.having-issues-subtitle {
  color: #123167;
  @include font-regular(14px, normal);
  margin-bottom: 30px;
}
.floating-border-non {
  select {
    border: 0;
  }
}
.border-none-mob {
  @media (max-width: 767px) {
    border: 0 !important;
  }
}
.add-member-floating {
  border: 1px solid #ced4da;
  .add-member-input {
    border: 0;
  }
  .__PrivateStripeElement {
    border: unset !important;
  }
  .StripeElement--empty {
    border: unset !important;
  }
  .StripeElement--complete {
    border: unset !important;
  }
  .StripeElement--invalid {
    border: unset !important;
  }
  .StripeElement--focus {
    border: unset !important;
  }
}
.picture-size-add {
  @include font-regular(12px, normal);
  color: #616161;
}
.add-picture-section {
  border-radius: 6px;
  border: 1px dashed rgba(129, 95, 68, 0.5);
  background: rgba(129, 95, 68, 0.1);
  width: 112px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
  flex-direction: column;
  text-align: center;
  color: #616161;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  @include font-regular(12px, normal);
  img {
    margin-bottom: 5px;
  }
  .uploaded-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 0;
  }
  &.border-remove {
    border: none !important;
    background-color: #ddd5cb !important;
  }
}
.profile-img-wrap {
  width: 32px;
  height: 32px;
  min-width: 32px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  @media (max-width: 767px) {
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
  }
  &.loveone-profile-wrap {
    width: 48px;
    height: 48px;
    min-width: 48px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      width: 40px !important;
      height: 40px !important;
      min-width: 40px !important;
    }
  }

  .profile-img {
    border-radius: 50%;
    border: 1px solid #fff;
    width: 100%;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}
// Tabel css
.table-container {
  width: 100%;
}
.table-head {
  border-radius: 0;
  background-color: #ffffff;
  display: flex;
  margin-bottom: 20px;

  &.bt-0 {
    border-top: 0;
  }
}
.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 100%;
  max-width: 100%;
}
.th {
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  color: #123167;
  @include font-regular(14px, normal);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .sort-icon-wrap {
    margin-left: 6px;
    img {
      cursor: pointer;
    }
  }
  &:last-child {
    justify-content: flex-start;
  }
}

.table-body {
  display: flex;
  flex-direction: column;
  margin: 0;
  .table-row {
    background-color: #ffffff;
    margin-bottom: 16px;
    position: relative;
    &.inactive {
      .td {
        opacity: 0.5;
        pointer-events: none;
        &:last-child {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.td {
  display: flex;
  align-items: center;
  padding: 0 8px 0 0;
  word-break: break-word;
  position: relative;
  color: #222;
  line-height: normal;
  @include font-regular(14px, normal);
  &.remove {
    color: #df2d0f;
    text-align: right;
    @include font-bold(14px, normal);
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
    &.inactive {
      opacity: 0.3;
    }
  }
  &:last-child {
    padding: 0;
  }
  .dropdown-manage {
    .dropdown-toggle {
      color: #df2d0f;
      text-align: right;
      @include font-bold(14px, normal);
      line-height: 20px;
      cursor: pointer;
      background: transparent;
      border: 0;
      padding: 0;
      text-decoration: none;
      outline: none;
      &::after {
        content: unset;
      }
      &.inactive {
        opacity: 0.3;
      }
    }
    .dropdown-menu {
      background-color: #fff;
      box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.11);
      width: 200px;
      padding: 20px;
      border: 0;
      transform: translate(0px, -28px) !important;
      .dropdown-title {
        color: #123167;
        line-height: normal;
        margin-bottom: 12px;
        @include fontPlayfair-bold(16px, normal);
      }
      .remove-btn-wrap {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .cancel-modal-btn {
          @include font-regular(14px, normal);
          color: #222;
          text-decoration: none;
        }
        .btn-primary-btn {
          height: 32px !important;
          padding: 8px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          width: auto !important;
        }
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -7px;
        top: auto;
        right: 15px;
        width: 15px;
        height: 15px;
        background: white;
        transform: rotate(45deg);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      }
      &[data-popper-placement^="bottom-end"] {
        transform: translate(0px, 31px) !important;
        &::after {
          top: -7px;
          right: 15px;
          box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
.co-owner-table {
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &:nth-child(2) {
        flex: 0 0 35%;
        max-width: 35%;
      }
      &:nth-child(3) {
        flex: 0 0 23%;
        max-width: 23%;
      }
      &:nth-child(4) {
        flex: 0 0 12%;
        max-width: 12%;
      }
      .ellipsis-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.manage-co-owner-modal {
  max-width: 666px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 767px) {
    height: 100%;
    display: block;
  }
}
.cancel-blue {
  color: #123167;
}
.new-input-file-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  .add-picture-section {
    margin-bottom: 0;
  }
}

// chat message
.view-info-section {
  &.chat-message-section {
    .first-border {
      .second-border {
        padding: 56px 60px;
      }
    }
  }
}
.chat-message-main {
  min-height: 300px;
  .chat-message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding: 0 40px;
    @media (max-width: 767px) {
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }
  }
  .chat-inner-wrap {
    padding: 0 50px 0 60px;
    max-height: 400px;
    overflow: auto;
    margin-right: 10px;
    @media (max-width: 767px) {
      padding: 0 12px 0 0px;
      max-height: 325px;
      overflow-y: auto;
      margin-right: -12px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd5cb;
      border-radius: 10px;
    }
    .chat-message-inner-wrap {
      position: relative;
      margin-bottom: 14px;
      border-bottom: 1px solid #ddd5cb;
      &:last-child {
        border: 0;
        .chat-message-des {
          margin-bottom: 0;
        }
      }
      .user-profile-wrap {
        margin-bottom: 2px;
        .user-profile-img {
          width: 32px;
          height: 32px;
          min-width: 32px;
          margin-right: 10px;
          border-radius: 50%;
          img {
            border-radius: 50%;
          }
        }
        .user-profiel-info {
          .user-profile-title {
            font-size: 20px;
            @media (max-width: 390px) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 170px;
            }
          }
        }
        .chat-message-date {
          display: block;
          margin-left: auto;
          color: rgba(34, 34, 34, 0.6);
          @include font-medium(16px, normal);
        }
      }
      &.dotted {
        border: 2px solid #885d40 !important;
      }
    }
  }
  .share-message {
    padding: 0 60px;
    position: relative;
    margin-top: 40px;
    @media (max-width: 767px) {
      padding: 0;
    }
    .textarea-message {
      margin-bottom: 0;

      .form-floating-add {
        margin-bottom: 0 !important;
        padding-right: 4px;
        background-color: #fff;
        &::after {
          content: unset;
        }
        &::before {
          display: none;
        }
        .form-control {
          min-height: 60px;
          max-height: 140px;
          height: 60px;
          padding: 17px 96px 0px 30px !important;
          color: #000;
          @include font-regular(20px, 28px);
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #ddd5cb;
            border-radius: 10px;
          }
          &::placeholder {
            color: #222 !important;
          }
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: normal;
            padding: 17px 96px 0px 16px !important;
            &::placeholder {
              font-size: 16px !important;
            }
          }
        }
      }
    }
    .send-btn-wrap {
      position: absolute;
      right: 72px;
      bottom: 12px;
      z-index: 2;
      @media (max-width: 767px) {
        right: 12px;
      }
      img {
        cursor: pointer;
        &.send-img {
          margin-left: 18px;
        }
      }
    }
  }
}

.user-profile-wrap {
  display: flex;
  align-items: center;
  .user-profile-img {
    width: 56px;
    height: 56px;
    min-width: 56px;
    margin-right: 12px;
    border-radius: 50%;
    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
      min-width: 40px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .user-profiel-info {
    .user-profile-title {
      color: #123167;
      @include font-medium(22px, normal);
      margin-bottom: 2px;
    }
    .user-profile-mail {
      color: #123167;
      @include font-medium(16px, normal);
      word-break: break-word;
    }
  }
}
.done-btn {
  width: 116px;
  height: 50px;
  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 16px;
  }
}
.chat-message-des {
  color: #222;
  @include font-regular(20px, 32px);
  white-space: pre-line;
  @media (max-width: 767px) {
    @include font-regular(16px, 28px);
  }
}

.page-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd5cb;
  margin: 23px 32px 19px;
  @media (max-width: 991px) {
    max-width: 670px;
    overflow: auto;
    margin: 23px 16px 19px;
    white-space: nowrap;
  }
  @media (max-width: 767px) {
    margin: 23px 16px 19px;
  }
  .add-detail {
    margin-right: 20px;
    .add-detail-text {
      white-space: nowrap;
    }
  }
}

// Edit Memories Modal
.edit-memory-modal {
  max-width: 982px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
  .modal-content {
    padding: 56px 56px 45px !important;
    margin: 50px 0;
    @media (max-width: 768px) {
      margin: 0;
    }
    @media (max-width: 767px) {
      padding: 56px 56px 100px !important;
    }
    @media (max-width: 575px) {
      padding: 30px 16px 100px !important;
    }
    .main-container .add-member-wrapper.wrapper-w-custom {
      width: 100% !important;
      margin: 0 0 49px;
      @media (max-width: 767px) {
        margin: 0 0 0px;
      }
      .share-obituary-modal {
        .modal-header {
          position: absolute;
          top: -38px;
          right: -39px;
          padding: 0;
          @media (max-width: 767px) {
            right: 0px;
            top: -10px;
          }
        }
        .modal-body {
          padding: 31px 31px 0px;
          @media (max-width: 767px) {
            padding: 0px 0px 30px;
          }
          .select-modal-sec {
            padding: 0;
            .modal-titles {
              font-size: 38px;
              font-weight: 600;
              margin-bottom: 8px;
            }
            .modal-subtitles {
              font-weight: 400;
              font-size: 16px;
              color: #885d40;
              margin-bottom: 20px;
            }
            .title-right-sec {
              .add-picture-section {
                width: 173px;
                @media (max-width: 767px) {
                  width: 226px;
                  height: 127px;
                }
                @media (max-width: 480px) {
                  width: 156px;
                  height: 88px;
                }
                .add-img-del {
                  bottom: -1px;
                  right: -1px;
                  z-index: 2;
                  img {
                    margin-bottom: 0;
                  }
                }
                &.border-remove {
                  border: 0;
                }
              }
            }
          }
        }
        .add-pic-flax-justify {
          justify-content: space-between !important;
          display: flex;
          &.add-pic {
            margin-bottom: 16px;
            @include font-semi-bold(12px, normal);
            color: #885d40;
            text-decoration: underline;
            @media (max-width: 767px) {
              margin-left: 0px;
              flex-direction: column;
              margin-bottom: 20px;
            }
            img {
              margin-right: 6px;
            }
          }
          .add-end-add-pic {
            display: flex;
            align-items: center;
            a {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              color: #885d40;
              margin-left: auto !important;
              img {
                margin-right: 6px;
              }
              .pic-video-file-input {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .form-label {
                  margin-bottom: 0;
                  cursor: pointer;
                }
                .form-control {
                  position: absolute;
                  opacity: 0;
                  width: 100px;
                  height: 30px;
                }
              }
            }

            .margin-right-30 {
              margin-right: 30px;
            }
          }
        }
        .about-memorial-section {
          width: 100%;
          padding: 0 32px 32px 32px;
          @media (max-width: 991px) {
            padding: 0 31px 32px 31px;
          }
          @media (max-width: 767px) {
            padding: 0 0 32px 0;
          }
          .sub-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              @include font-regular(12px, normal);
              color: #885d40;
            }
          }
          h5 {
            @include font-semi-bold(16px, normal);
            color: #123167;
          }
          .paragraph {
            max-width: 788px;
            width: 100%;
            height: 404px;
            background-color: #fff;
            border: solid 1px #e7dfc3;
          }
          .file-input-wrapper {
            .custom-file-input {
              margin-right: 0;
              background-position-y: 40px;
              img {
                margin-bottom: 0;
              }
              @media (max-width: 992px) {
                width: 173px;
              }
              @media (max-width: 767px) {
                width: 100%;
                height: 145px;
              }
              @media (max-width: 414px) {
                height: 101px;
              }

              .upload-pic {
                border-radius: 6px;
              }
              .delete-img-btn {
                position: absolute;
                right: 20px;
                bottom: -1px;
                padding: 0;
                img {
                  margin-bottom: 0;
                  border-radius: 0;
                }
              }
              .drag-img {
                position: absolute;
                right: -1px;
                bottom: -1px;
                padding: 0;
                margin: 0;
                z-index: 2;
                img {
                  border-radius: 0;
                }
              }
              .upload-video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 6px;
              }
            }
          }
        }

        .row-heading {
          font-family: "Manrope", sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: normal;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: #123167;
          &.row-top-margin {
            margin-top: 45px;
            @media (max-width: 767px) {
              margin-top: 30px;
            }
          }
          @media (max-width: 767px) {
            margin-bottom: 10px;
          }
        }
        .row {
          &.video-wrapper {
            margin: 0 -12px;
            .custom-file-input {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}

// Three Dot dropdown
.home-card-dropdown {
  .dropdown-toggle {
    &::after {
      background: url(./../src/assets/images/3-dot-img.svg) no-repeat center;
      border: none;
      position: absolute;
      right: -10px;
      top: 50%;
      width: 30px;
      height: 31px;
      transform: translateY(-50%);
    }
  }
  .dropdown-menu {
    transform: translate(3px, 35px) !important;
    min-width: 203px;
    border: none;
    box-shadow: 4px 8px 24px 0px rgba(156, 122, 3, 0.05);
  }
}
.reomove-modal-wrap {
  position: absolute;
  bottom: 44px;
  right: -5px;
  z-index: 9;
  &.remove-text {
    bottom: 94px;
  }
  .reomove-modal-sec {
    width: auto;
    padding: 20px;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    text-align: center;
    border-radius: 6px;
    &:after {
      content: "";
      position: absolute;
      bottom: -21px;
      right: 9px;
      margin-top: -8px;
      border-width: 11px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
    .reomove-title {
      @include font-bold(16px, normal);
      color: #123167;
      margin-bottom: 16px;
      @media (max-width: 390px) {
        font-size: 14px;
      }
    }
    .reomove-btn {
      height: 32px;
      width: 116px;
      margin-bottom: 9px;
      @include font-medium(12px, normal);
      color: #fff;
      padding: 0;
    }
    .cancel-modal-btn {
      @include font-regular(12px, normal);
      color: #222;
      text-decoration: none;
    }
  }
}

.custom-file-input {
  background: url(./assets/images/add-picture-logo.svg) no-repeat center 46px;
  position: relative;
  margin: 0 0 24px 0;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 112px;
  border: 1px dashed rgba(129, 95, 68, 0.5);
  border-radius: 6px;
  @media (max-width: 1024px) {
    background: url(./assets/images/add-picture-logo.svg) no-repeat center 30px;
  }

  @media (max-width: 992px) {
    width: 153px;
  }
  @media (max-width: 414px) {
    background-position: center 30px;
  }

  &.active {
    background-color: #fffdf6;
    border: unset;
  }
  img {
    margin-bottom: 3px;
  }
  span {
    @include font-semi-bold(12px, normal);
    margin-top: 34px;
    z-index: 6;
    color: rgba(34, 34, 34, 0.6);
  }
  input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }

  &.custom-video {
    background: url(./assets/images/add-video-logo.svg) no-repeat center 46px;
    @media (max-width: 1024px) {
      background: url(./assets/images/add-video-logo.svg) no-repeat center 30px;
    }
  }
  @media (max-width: 991px) {
    width: 130px;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 143px;
    background-position: center 35px;
  }
  @media (max-width: 414px) {
    height: 99px;
    background-position: center 30px;
  }
  .upload-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    position: relative;
    z-index: 2;
  }
  .upload-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    position: relative;
    z-index: 2;
  }
  .delete-img-btn {
    position: absolute;
    padding: 0;
    bottom: -3px;
    right: -2px;
    z-index: 2;
  }
  .video-icon {
    z-index: 6;
    width: 48px;
    height: 48px;
    position: absolute;
    img {
      height: 100%;
    }
  }
}

.custom-file-input-enable {
  border: unset;
  background: unset;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}
.comment-sec-empty {
  padding: 50px 50px 80px;
  .faq-empty-title {
    color: rgba(136, 93, 64, 0.3);
    text-align: center;
    @include font-bold(38px, normal);
    @media (max-width: 767px) {
      font-size: 32px;
    }
  }
}
// itinerary
.input-logo {
  position: absolute;
  right: 16px;
}
.itinerary-inner-wrap {
  .form-floating {
    &.birth-input {
      border: 1px solid #ced4da;
      background-color: #fff;
      &.time-input {
        border: 0;
        input {
          line-height: 1.25;
          width: 100%;
          border: 1px solid #ced4da !important;
          background-color: #fff !important;
          padding: 1rem 0.75rem;
          height: 60px;
          min-height: 60px;
          &:focus-visible {
            border: 1px solid #ced4da !important;
            background-color: #fff !important;
          }
          &::placeholder {
            @include font-regular(16px, normal);
            color: #616161;
          }
        }
      }

      input {
        border: 0;
        &:focus,
        &:not(:placeholder-shown) {
          padding: 16px 12px !important;
        }
        &::placeholder {
          // @include font-regular(12px, normal);
          color: #616161;
        }
      }
      table {
        .text-center {
          .btn-link {
            color: #222222 !important;
          }
        }
      }
    }
  }
  .co-owner-row {
    border-bottom: 1px solid #c7b575;
    padding-bottom: 40px;
    margin-bottom: 40px;
    &:last-child {
      padding-bottom: 0;
      border: 0;
    }
  }
}
.itinerary-form-title {
  color: #123167;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  font-family: "Manrope", sans-serif !important;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    font-size: 16px;
    width: calc(100% - 50px);
    display: inline-block;
  }
}
.guest-example-wrap {
  position: relative;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
  .guest-example-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .itinerary-form-title {
      margin-bottom: 0;
    }
  }
  .guest-example {
    border-radius: 8px;
    background: rgba(136, 93, 64, 0.1);
    padding: 10px 12px;
    color: #885d40;
    font-family: "Manrope", sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
  .edit-btn {
    margin-right: 0;
    @media (max-width: 767px) {
      width: 35px;
    }
  }
}

// View Etinerary
.view-etinerary-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .member-img-section {
    margin-bottom: 66px;
  }
  .view-etinerary-inner {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .add-more-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .map-logo {
      margin-right: 8px;
      cursor: pointer;
    }
    .edit-btn {
      margin: 0;
      color: #885d40;
      font-size: 18px;
      font-weight: 700;
      line-height: 24.59px;
      text-align: center;
    }
  }
}
.mat-typography {
  .view-etinerary-des {
    color: #222;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Manrope", sans-serif !important;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
    .highlight-text {
      font-family: "Manrope", sans-serif !important;
      font-size: 24px;
      font-weight: 700;
      line-height: 32.78px;
      text-align: center;
      color: #885d40;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: normal;
      }
    }
  }
}
.owner-modal-header {
  z-index: 1;
  position: relative;
  .btn-close {
    position: absolute;
    top: 26px;
    right: 26px;
    @media (max-width: 560px) {
      top: 10px;
    }
  }
}
.chat-empty-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  height: 100%;
  .chat-empty-img {
    margin-bottom: 10px;
  }
  .chat-empty-des {
    color: rgba(#885d40, 0.5);
    @include fontPlayfair-semi-bold(24px, normal);
    @media (max-width: 767px) {
      text-align: center;
      font-size: 20px;
    }
  }
}
// .reply-btn-wrap{
//     margin-top: 20px;
// }
.reply-text {
  color: #885d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  .reply-logo {
    margin-left: 6px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.chat-message-dot {
  width: 16px;
  height: 16px;
  background-color: #885d40;
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
}
.image-video-wrap {
  // margin-top: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px 20px;

  .chat-msg-img-vedio {
    width: 182px;
    height: 112px;
    position: relative;
    border-radius: 8px;
    margin-top: 14px;
    .chat-msg-img,
    .selected-media-video,
    .chat-msg-upload-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .add-img-del {
      right: 0;
    }
  }
  .upload-video {
    width: 182px;
    height: 112px;
    object-fit: cover;
    border-radius: 8px;
  }
}
.chat-image-input-wrap {
  border: 1px solid #616161;
  background-color: #fff;
  padding: 0 16px 10px;
  margin-bottom: 0px;
  border-bottom: 0;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.chat-upload-img-wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.form-control[readonly] {
  background-color: #fff;
}
.private-msg-card {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd5cb;
  padding-bottom: 20px;
  .chat-message-des {
    margin-bottom: 10px;
  }
  .upload-video {
    width: 182px;
    height: 112px;
    object-fit: cover;
    border-radius: 8px;
  }
  .private-msg-img {
    width: 182px;
    height: 112px;
    object-fit: cover;
    border-radius: 8px;
    @media (max-width: 767px) {
      width: 150px;
      height: 100px;
    }
  }
  &:last-child {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
.frame-edit-btn {
  position: absolute;
  bottom: -20px;
  right: -10px;
  cursor: pointer;
  width: 52px;
  height: 52px;
  @media (min-width: 768px) {
    width: 72px;
    height: 72px;
    bottom: -24px;
    right: -10px;
  }
}
.edit-card-row {
  @media (max-width: 991px) {
    gap: 15px;
  }
  @media (max-width: 767px) {
    gap: 0;
  }
}

// Time Picker
.timepicker__header {
  background-color: #885d40 !important;
}
.timepicker-button > span {
  color: #885d40 !important;
}
.clock-face__number > span.active {
  background-color: #885d40 !important;
}
.clock-face__clock-hand {
  background-color: #885d40 !important;
}
.timepicker-backdrop-overlay {
  z-index: 1031 !important;
}
.timepicker-overlay {
  z-index: 1032 !important;
}
.clock-face__clock-hand_minute:before {
  border-color: #885d40 !important;
}
.drag-main-wrap {
  flex-wrap: wrap;
  gap: 20px;
  .drag-item {
    width: 33%;
    height: 112px;
  }
}

.profile-dropdown {
  a {
    color: #885d40 !important;
  }
}
.dndPlaceholder {
  background-color: #f9f7f6 !important;
  height: 166px;
}
.add-obituary-modal {
  .modal-header {
    @media (max-width: 767px) {
      padding: 0;
      border: 0;
    }
  }
  .modal-body {
    margin-top: 50px !important;
  }
}
