.w-100 {
  width: 100% !important;
}
.sign-guestbook-btn {
  width: 230px;
  height: 50px;
  margin-right: 20px;
  padding: 0 !important;
}
.add-info-padding {
  padding-top: 30px;
  @media (max-width: 767px) {
    padding-top: 0;
  }
}
.guestbook-title {
  @include fontPlayfair-semi-bold(38px, normal);
  color: #123167;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    @include fontPlayfair-semi-bold(24px, normal);
  }
}
.guestbook-subtitle {
  @include font-regular(16px, normal);
  color: #123167;
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 767px) {
    @include font-regular(14px, normal);
    margin-bottom: 20px;
  }
  span {
    color: #885d40;
    font-weight: 700;
  }
}
// for floating label
.form-floating-add {
  .form-control {
    border: unset !important;
  }
}

// common modal small
.custom-modal {
  max-width: 539px;
  .modal-content {
    padding: 30px 20px;
    .modal-header {
      justify-content: flex-end;
      border: 0;
      padding: 0;
      align-items: end;
    }
    .modal-body {
      text-align: center;
      padding: 0;
      .modal-title {
        @include fontPlayfair-bold(32px, normal);
        color: #123167;
        margin-bottom: 12px;
      }
      .modal-subtitle {
        @include font-medium(14px, normal);
        color: #222;
        margin-bottom: 30px;
      }
      .modal-titles {
        @include fontPlayfair-bold(38px, normal);
        color: #123167;
        margin-bottom: 8px;
      }
      .modal-subtitles {
        @include font-regular(16px, normal);
        color: #885d40;
        margin-bottom: 30px;
      }
      .fill-button {
        width: 215px;
        height: 50px;
        margin-bottom: 24px;
      }
    }
  }
}

.social-modal {
  max-width: 681px;
}

// guestbook-request-wrapper
.guestbook-request-wrapper {
  margin-bottom: 100px;
  @media (max-width: 767px) {
    margin-bottom: 160px;
  }

  .guestbook-request-title {
    @include fontPlayfair-semi-bold(38px, normal);
    color: #123167;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }
  .guestbook-request-btn {
    margin-bottom: 20px;
    border: 1px solid #885d40;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    max-width: 564px;
    .position-relative {
      width: 33.33%;
      flex: 1 1;
      border-right: 1px solid #885d40;
      .btn-primary-msg {
        width: 100%;
        min-width: 100%;
        font-size: 16px;
        border: 0;
        border-radius: 0;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 47px;
        padding: 6px 16px;
        &.active {
          background-color: #885d40;
        }
      }
      &:last-child {
        border-right: 0px;
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 12px !important;
      overflow: hidden !important;
    }
    .btn-primary-msg {
      margin-right: 20px;
      @media (max-width: 767px) {
        margin-right: 10px;
        width: 103px;
        height: 28px;
        font-size: 12px !important;
        min-width: auto;
        line-height: inherit;
        padding: 6px 16px !important;
      }
    }
    &.inner-tab {
      margin-bottom: 20px;
      border: 0;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      height: 49px;
      width: 100%;
      max-width: 100%;
      @media (max-width: 767px) {
        overflow-x: auto;
      }
      .position-relative {
        width: auto;
        flex: unset;
        border-right: 0;
        margin-right: 20px;
        .btn-primary-msg {
          padding: 6px 20px;
          border-radius: 6px;
          background-color: rgba(204, 184, 171, 1);
          height: 34px;
          color: #885d40;
          &.active {
            background-color: #885d40;
            color: #fff;
          }
        }
      }
    }
  }
  .text-limit-sec {
    text-align: right;
    .text-limit {
      @include font-medium(20px, normal);
      color: #222;
      margin-bottom: 20px;
    }
  }
  .msg-request-wrapper {
    border: 1px solid rgba(34, 34, 34, 0.6);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 30px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      padding: 15px;
      margin-bottom: 16px;
    }
    .msg-title-sec {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      @media (max-width: 767px) {
        margin-bottom: 0px;
      }

      .msg-title {
        @include font-semi-bold(24px, normal);
        color: #123167;
        margin-right: 10px;
        @media (max-width: 767px) {
          font-size: 16px;
          margin-right: 6px;
        }
      }
      .msg-date {
        @include font-medium(16px, normal);
        color: #616161;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    .msg-subtitle {
      @include font-medium(16px, normal);
      color: #616161;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    .msg-title-desc {
      @include font-regular(20px, 1.6);
      color: #222;
      margin-bottom: 20px;
      word-break: break-word;
      @media (max-width: 767px) {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
    .title-btn-sec {
      display: flex;
      button {
        height: 50px;
        width: 160px;
        margin-right: 15px;
        padding: 0;
        @media (max-width: 767px) {
          width: 99px;
          height: 32px;
          font-size: 12px;
        }
      }
    }
  }
}

// button sec
.btn-primary-msg {
  @include font-bold(16px, normal);
  border-radius: 8px;
  border: solid 1px #885d40;
  background-color: #e7dfc3;
  color: #885d40;
  min-width: 140px;
  height: 34px;
  position: relative;
  &.active {
    background-color: #885d40;
    color: #fff;
  }
}
.btn-close-img {
  position: absolute;
  right: 28px;
  top: 10px;
  cursor: pointer;
  @media (max-width: 767px) {
    right: 16px;
    top: 8px;
    width: 14px;
  }
}
.export-guest-sec {
  .sales-drop-icon {
    transform: rotate(0deg);
    &.open {
      transform: rotate(180deg);
    }
  }
  // }
}

// Multi line tooltip
.multiline-tooltip {
  .tooltip {
    position: relative;
    display: inline-block;
    border: 0;
    opacity: 1;
    z-index: 9;
    color: #fff;
    .tooltiptext {
      visibility: hidden;
      width: auto;
      min-width: 140px;
      border-radius: 10px;
      border: solid 1px #d6c788;
      background-color: #fffdf6;
      text-align: left;
      white-space: normal;
      padding: 14px 14px 10px 14px;
      // Position the tooltip
      position: absolute;
      z-index: 9999;
      bottom: 38px;
      left: -15px;
      right: auto;
      &::before {
        content: "";
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        top: 100%;
        left: 20%;
        margin-left: -10px;
        border-top: 10px solid #d6c788;
        // margin-top: 5px;
      }
      &::after {
        content: "";
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        top: 100%;
        left: 20%;
        margin-left: -10px;
        border-top: 10px solid white;
        margin-top: -1px;
        z-index: 1;
      }
    }
  }
  .tooltip-inside {
    background-color: transparent;
  }
  .tooltip:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
  .text-tooltip-title {
    @include font-semi-bold(16px, normal);
    color: #123167;
    margin-bottom: 2px;
  }
  .text-tooltip {
    @include font-regular(12px, normal);
    color: #616161;
    white-space: nowrap;
    margin-bottom: 3px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: #222;
      a {
        color: #222;
        text-decoration: none;
      }
    }
  }
}

// message-guestbook-wrapper

.message-guestbook-wrapper {
  display: flex;
  border-bottom: 1px solid #e7dfc3;
  padding: 20px 0;
  position: relative;
  &.padding-more {
    @media (max-width: 767px) {
      padding: 20px 0 40px 0;
    }
  }
  &:last-child {
    border-bottom: none;
    .msg-title-sec {
      margin-bottom: 0;
    }
  }
  .message-guestbook-left {
    padding-top: 7px;
    padding-right: 15px;
    @media (max-width: 575px) {
      padding-top: 3px;
      padding-right: 8px;
    }
    img {
      @media (max-width: 575px) {
        width: 17px;
        height: 18px;
      }
    }
  }
  .message-guestbook-right {
    width: 100%;
  }
  .msg-title-sec {
    display: flex;
    width: 100%;
  }
  .message-guestbook-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .date-sec {
      display: flex;
      align-items: flex-end;
      .message-title {
        @include font-semi-bold(24px, normal);
        color: #123167;
        margin-right: 8px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      .message-date {
        @include font-medium(16px, 1.6);
        color: #616161;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
  .message-remove {
    @include font-semi-bold(16px, normal);
    color: #df2d0f;
    cursor: pointer;
    text-decoration: underline;
    position: absolute;
    top: -35px;
    right: 0;
    @media (max-width: 767px) {
      font-size: 12px;
      position: relative;
      top: auto;
    }
  }
  .message-title-desc {
    @include font-regular(20px, 1.6);
    color: #222;
    @media (max-width: 767px) {
      @include font-regular(12px, 1.83);
    }
    // margin-bottom: 20px;
  }
  .message-img-sec {
    display: flex;
    .message-img {
      border-radius: 11px;
      border: solid 1px #e7dfc3;
      width: 180px;
      height: 109px;
      margin-right: 10px;
      img {
        border-radius: 11px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.message-see-more {
  position: absolute;
  bottom: -7px;
  left: 46%;
  background: #fffdf6;
  padding: 0 7px;
  @include font-semi-bold(14px, normal);
  color: #885d40;
  text-decoration: underline;
  @media (max-width: 767px) {
    left: 40%;
  }
}
.guest-signin-modal {
  max-width: 539px;
  .modal-content {
    padding: 30px 20px;
    .modal-header {
      justify-content: flex-end;
      border: 0;
      padding: 0;
      align-items: end;
    }
    .modal-body {
      text-align: center;
      padding: 0;
      .modal-title {
        @include fontPlayfair-bold(32px, normal);
        color: #123167;
        margin-bottom: 4px;
      }
      .modal-subtitle {
        @include font-medium(14px, normal);
        color: #222;
        margin-bottom: 30px;
      }
      .guest-signin-input {
        max-width: 380px;
        margin: 0 auto;
        margin-bottom: 48px;
      }
      button {
        width: 215px;
        height: 50px;
        margin-bottom: 20px;
      }
    }
  }
}
.cancel-btn {
  @include font-medium(14px, normal);
  color: #222;
  @media (max-width: 767px) {
    position: absolute;
    top: -39px;
    right: 0;
  }
}
.add-msg-submit-msg {
  @include font-regular(16px, normal);
  color: #885d40;
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
}
.add-img-del {
  position: absolute;
  bottom: -2px !important;
  right: -2px !important;
  background: #fff;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border: 1px solid #e7dfc3;
  cursor: pointer;
  img {
    width: auto !important;
    height: auto !important;
  }
  &.view-info-del {
    bottom: 7px !important;
    right: 6px !important;
    z-index: 2;
  }
  &.view-right-12 {
    right: 12px;
    @media (max-width: 767px) {
      right: 6px;
    }
  }
  &.view-right-6 {
    right: 6px;
  }
  .border-none {
    border: 0 !important;
  }
}
.res-p-0 {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}
.res-p-16 {
  @media (max-width: 767px) {
    padding: 0 16px;
  }
}
.custom-padding-edit-memo{
  @media (max-width: 767px) {
    padding: 0 16px 1px !important;
  }
}
.res-pt-0 {
  @media (max-width: 767px) {
    padding-top: 0 !important;
  }
}
.res-pb-0 {
  @media (max-width: 767px) {
    padding-bottom: 0 !important;
  }
}
.res-mt-0 {
  @media (max-width: 767px) {
    margin-top: 0 !important;
  }
}
.res-mb-0 {
  @media (max-width: 767px) {
    margin-bottom: 0 !important;
  }
}
.modal-header {
  z-index: 9;
}
// .mat-typography.scroll-remove{
//     overflow: hidden !important;
// }
.custom-input-file {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
  width: 100%;
}

.add-pic-sec-wrap {
  .add-img-title {
    @include font-medium(12px, normal);
    color: #123167;
    margin-bottom: 10px;
  }
  .add-pic-wrap {
    .add-pic-sec {
      position: relative;
      .add-pic-section {
        width: 173px;
        height: 112px;
        border-radius: 6px;
        border: dashed 1px #e7dfc3;
        background-color: rgba(156, 122, 3, 0.05);
        position: relative;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .add-picture-sec {
          width: 173px;
          height: 112px;
          border-radius: 6px;
        }
        .upload-img {
          width: 34px;
          height: 34px;
          opacity: 0.3;
        }
        .add-pic-title {
          margin-top: 5px;
          @include font-semi-bold(12px, normal);
          color: #885d40;
          opacity: 0.3;
        }
        &.add-pic-white {
          background-color: unset;
        }
        &.add-pic-none {
          border: 1px dashed rgba(129, 95, 68, 0.5);
          background-color: rgba(129, 95, 68, 0.1);
        }
      }
    }
  }
}
.textarea-message {
  padding: 0 25px;
  margin-bottom: 20px;
  &.textarea-padding {
    padding: 0;
    .form-floating {
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }
  }
  .form-floating {
    position: relative;
    label {
      z-index: 2;
    }
    &:before {
      background: #fff;
      height: 28px;
      content: "";
      display: block;
      position: absolute;
      left: 1px;
      right: 22px;
      top: 1px;
      z-index: 1;
    }
    &:after {
      background: #fff;
      height: 10px;
      content: "";
      display: block;
      position: absolute;
      left: 1px;
      right: 22px;
      bottom: 1px;
      z-index: 1;
    }
  }
  textarea {
    height: 190px;
    position: relative;
    resize: none;
  }
}
input[type="file"]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.date-picker-sec {
  padding: 9px 16px 9px 16px;
  border: solid 1px #616161;
  background-color: #fff;
  &:hover {
    box-shadow: unset;
    border-color: #bf9b41;
  }
  .mat-date-range-input-end-wrapper {
    @media (max-width: 768px) {
      width: 160px;
    }
  }
  .mat-datepicker-toggle {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }
    button:focus:not(:focus-visible) {
      background-color: transparent !important;
    }
    button {
      background: url(./../../../assets/images/icons-calendar-gray.svg)
        no-repeat center;
    }
  }
  .mat-date-range-input-container {
    input {
      @include font-regular(16px, normal);
      color: #222;
      cursor: pointer;
    }
    .mat-date-range-input-mirror {
      @include font-regular(16px, normal);
      color: #222;
    }
  }
  .mat-date-range-input {
    margin-top: 3px;
  }
  mat-label {
    @include font-regular(12px, normal);
    color: #616161;
  }
  .mat-button-wrapper {
    opacity: 0;
  }
}
.mat-calendar-body-selected {
  background-color: #885d40 !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #ddd5cd !important;
}
.mat-calendar-body-in-range::before {
  background: rgba(231, 223, 195, 0.5);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #885d40 !important;
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #885d40 !important;
  &:hover {
    background-color: #e7dfc3 !important;
  }
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.max-width-605 {
  max-width: 605px;
}
.scroll-stop {
  @media (max-width: 767px) {
    overflow: hidden;
  }
}

.select-custom-view {
  position: relative;
  .select-custom {
    width: 100%;
    height: 60px;
    background: #fff;
    border: 1px solid #616161;
    color: #222222;
    border-radius: 3px;
    margin-bottom: 24px;
    text-align: left;
    padding-top: 20px;
    padding-left: 15px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .scroll-host {
      border: 1px solid #616161;
      max-height: 140px;
      background: #fff !important;
      padding-top: 10px;
      width: 100%;
      top: 0;
      .ng-option {
        padding: 12px 30px;
        color: #222;
        @include font-regular(14px, normal);
        &:hover {
          background-color: #e7dfc3;
        }
      }
    }
    .ng-dropdown-panel {
      left: 0;
    }
  }
  .custom-label {
    position: absolute;
    top: 22px;
    left: 16px;
    @include font-regular(16px, normal);
  }
  .ng-select-container {
    box-shadow: unset !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center !important;
    min-height: auto !important;
    height: auto !important;
  }
  .ng-arrow-wrapper {
    display: none !important;
  }
  .ng-value-container {
    input {
      cursor: pointer !important;
    }
  }
  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    top: auto !important;
  }
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #885d40;
  border-color: #885d40;
}
.mat-calendar-table-header {
  .mat-calendar-abbr {
    text-decoration: none !important;
  }
}
.share-social-wrap {
  share-buttons {
    padding-top: 0 !important;
  }
  .sb-group {
    display: flex;
    align-items: center;
    margin: 0 -17.5px;
    @media (max-width: 767px) {
      flex-direction: column;
      margin: 0;
    }
    share-button {
      width: 100%;
      margin: 0 17.5px;
      margin-bottom: 12px;
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
      .sb-wrapper {
        background: #fff;
        .sb-content {
          display: flex;
          align-items: center;
          justify-content: center;
          .sb-icon {
            margin-right: 10px;
            fa-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              .fa-envelope {
                width: 20px;
                height: 20px;
              }
              .fa-twitter {
                width: 20px;
                height: 20px;
              }
            }
          }
          .sb-text {
            @include font-medium(16px, normal);
            color: #222;
          }
        }
      }
    }
    .sb-button {
      .sb-wrapper {
        padding: 19px;
        width: 100%;
        border: 1px solid #885d40 !important;
        @media (max-width: 767px) {
          padding: 17px;
        }
      }
    }
  }
}

.sb-button[ng-reflect-button="facebook"] .sb-facebook .sb-icon {
  background: transparent;
}
.sb-button[ng-reflect-button="facebook"] .sb-facebook .fa-facebook-f {
  background: url(./../../../assets/images/icon-facebook.svg) no-repeat center;
  color: transparent;
  width: 24px;
  height: 24px;
}
//   .sb-button[ng-reflect-button="email"] .sb-email .sb-icon { background: transparent; }
//   .sb-button[ng-reflect-button="email"] .sb-email .fa-envelope { background: url(./../../../assets/images/icon-email.png) no-repeat center; color: transparent; width: 24px; height: 24px; }
//   .sb-button[ng-reflect-button="email"] .sb-email .sb-text { font-size: 0px!important; }
//   .sb-button[ng-reflect-button="email"] .sb-email .sb-text:after { content: 'Gmail'; font-size:16px!important; }
.sb-button[ng-reflect-button="twitter"] .sb-twitter .sb-icon {
  background: transparent;
}
.sb-button[ng-reflect-button="twitter"] .sb-twitter .fa-twitter {
  background: url(./../../../assets/images/icon-twitter.svg) no-repeat center;
  color: transparent;
  width: 24px;
  height: 24px;
}
.file-bg-white {
  margin-bottom: 12px;
  border: 1px dashed rgba(129, 95, 68, 0.5);
  background-color: rgba(129, 95, 68, 0.1) !important;
}

.support-file {
  font-size: 12px;
  display: block;
  margin-left: 10px;
  color: rgba(34, 34, 34, 0.6);
  @include font-regular(12px, normal);
}

//   FAQ
.faq-text-box {
  border: 1px solid #885d40;
  padding: 20px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  .faq-text-head {
    color: #222;
    margin-bottom: 8px;
    @include fontPlayfair-medium(20px, normal);
    word-break: break-word;
  }
  .faq-text-para {
    color: #222;
    @include font-regular(20px, 32px);
    word-break: break-word;
    margin-bottom: 0;
  }
}
.signin-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
