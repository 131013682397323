/* column mixin */
$totalColumns : 50;

$colWidths: ();
@for $i from 1 through $totalColumns {
  @for $j from 1 through $i {
    $w: ($j);
    @if not index($colWidths, $w ) {
      .table-column-#{$i} {
        width: $w * 1%;
      }
      $colWidths: append($colWidths, $w, comma)
    }
  }
}
