$phone-landscape: 576px;
$tablet-portrait: 768px;
$tablet-landscape: 992px;
$desktop: 1200px;
$desktop-hd: 1400px;
$font-weight: 400;
$font-size-base: 14px;
$line-height-base: 1;
%all-animation {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

@mixin vendor-prefixes($property, $value) {
  #{$property}: $value;
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
}

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

$h1-color: #123167 !default;
$h2-color: #885d40 !default;
// Header
$text-theme-color: #885d40 !default;
$header-text: #123167 !default;
$para-theme-text: #123167 !default;
$header-text-active: #885d40 !default;
$header-background: transparent;
$nav-link-focus: #e2e4e7 !default;
$nav-link-hove: #e2e4e7 !default;
$label-text-color: #616161 !default;
// Button
$primary-button-background: #885d40 !default;
$primary-button-text: #fff !default;
$primary-border-background: #885d40 !default;
$primary-button-text-color: #0053ff !default;
$primary-modal-button-text-color: #0053ff !default;
$plans-draft-status-bg-color: #f20000 !default;
$plans-schedule-status-bg-color: #00852c !default;
// $secondary-button-background:                   #bf9b41 !default;
// $secondary-button-text:                         #FFFFFF !default;
// $btn-link-background:                           #FFFFFF !default;
// $btn-link-text:                                 #58ac86 !default;
// $btn-link-border:                                 transparent !default;
// Form
// $input-background:                              rgba(#fff, 0.1);
// $input-border-color:                            #dbdbdb;
$input-border-active-color: #885d40;
$section-border-color: #885d40;
$border-bottom-color: #e7dfc3;
$input-border-color: #616161;
$dropdown-item-focus-background: #ddd5cb !default;
$dropdown-item-active-color: #885d40 !default;
$dropdown-item-hover-background: #0053ff;
$link-text-color: #885d40 !default;
$signin-title-color: #123167 !default;
$signin-para-color: #222222 !default;
$note-color: #885d40 !default;
// $input-placeholder:                             #333;
// $input-text:                                    #fff;
// $input-label-color:                             #606060;
// $error-color:                                   #da4949;
$success-color: #0053ff;
// $warning-color:                                 #bf9b41;
// $input-focus-border-color:                      #3a362a;
// $email-color: 								    #5e5f63;
//-----------Custom variables will goes here
$white: #ffffff;
$black: #000000;
$grey: #707070;
$body-background: #fff;
$theme-color: #885d40 !default;
$theme-text: #123167 !default;
$theme-secondary-text: #0f1d17 !default;
$secondary-para-text: #828282 !default;
$theme-main-background: #f5f6fa !default;
$link-color: #0053ff !default;
$main-border-color: #e4e4e4 !default;
$caption-color: #5e6662;
$navitem-active-border-color: #0053ff;
$border-color: #0053ff;
$icon-color: #818694;
// tables
$table-row1-background-color: #0053ff;
$table-row2-background-color: #540bbf;
$table-row3-background-color: #008918;
$table-row4-background-color: #890064;
$table-h1-color: #123167;
// Flex box
@mixin flexboxcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexboxspace {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flexboxstart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flexboxstartleft {
  display: flex;
  justify-content: flex-start;
  align-items: left;
}

@mixin flexboxsend {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
